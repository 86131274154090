var requestPagi = function (rel, postperpage, category, page, order, orderby, container) {
  if (!container) {
    container = '#postcontainer';
  }
  if (!order) {
    order = 'ASC';
  }
  if (!orderby) {
    orderby = 'name';
  }
  if (!category) {
    category = jQuery(container).attr('data-category');
  }
  jQuery(container).addClass('loading');
  jQuery.post(ajaxurl,
    {
      action: 'pagi',
      postperpage: postperpage,
      category: category,
      page: page,
      order: order,
      orderby: orderby
    }, function (data) {
      var html = '';
      jQuery(data.posts).each(function () {
        html += '' +
          '<div class="inbook">' +
          '  <div class="books">' +
          '    <a class="acquistalo" href="' + this.url + '">' +
          '      <div class="book">' +
          '        <div class="linkaggio">' +
          '          <div class="centering">' +
          '            <div class="clr"></div>' +
          '            ACQUISTA' +
          '          </div>' +
          '        </div>' +
          '        <img src="' + this.image + '" style="display: block;" alt="' + this.title + '" title="' + this.title + '">' +
          '      </div>' +
          '    </a>' +
          '  </div>' +
          '  <div class="titlepricont">' +
          '    <div class="prezzolibro">' + this.price + '</div>' +
          '    <h4>' + this.title + '</h4>' +
          '  </div>' +
          '</div>';
      });
      html += '<div class="loaderlayer"></div>';
      var pagi = '';


      if (data.pagination.before !== '') {
        pagi += '<li class="firstpage" data-rel="' + rel + '" data-page="1" onclick="pagina(this);"> &laquo;</li>' +
          '<li>' + data.pagination.before + '</li>';
      }
      var activepage = '';
      for (var indi = data.pagination.init; indi <= data.pagination.end; indi++) {
        activepage = '';
        if (indi === page) {
          activepage = 'activepage';
        }

        pagi += '<li onclick="pagina(this);"  class="numerico ' + activepage + '" data-rel="' + rel + '" data-page="' + indi + '">' + indi + '</li>';
      }
      if (data.pagination.after !== '') {
        pagi += '<li>' + data.pagination.after + '</li>' +
          '<li onclick="pagina(this);" class="lastpage" data-rel="' + rel + '" data-page="' + data.pagination.maxpage + '"> &raquo;</li>';

      }

      jQuery(container).html(html);
      jQuery('.paginazione ul').html(pagi);
      jQuery(container).removeClass('loading');

    }, 'json');
};

var pagina = function (element) {

  if (jQuery(element).hasClass('activepage')) {
    return false;
  }
  var rel = jQuery(element).data('rel');
  var postperpage = jQuery(rel).data('postsperpage');
  var category = jQuery(rel).data('category');
  var container = jQuery(rel).data('cont');
  var page = jQuery(element).data('page');
  var order = jQuery(rel + ' .orderby').val();
  var orderby = jQuery(rel + ' .orderby :selected').data('orderby');
  requestPagi(rel, postperpage, category, page, order, orderby, container);

};

(function ($) {

  "use strict";

  $(document).ready(function () {

    $('#mothcat').on('change', function () {
      var val = parseInt(jQuery(this).val());
      $('#catscollana optgroup').each(function () {
        if (val !== parseInt(jQuery(this).data('vallabel')) && val) {
          jQuery(this).css('display', 'none');
        } else {
          jQuery(this).css('display', 'block');
        }
      });
    });

    $("img.lazy").lazyload(
      {
        data_attribute: "original",
        threshold: 50
      });

    $('.switch').on('click', function () {
      var id = $(this).data('rel');
      $(this).toggleClass('listing');
      $(id).toggleClass('listing');
    });

    $('.orderby').on('change', function () {
      //$('.paginazione ul li.activepage').trigger('click');
      var rel = jQuery(this).data('rel');
      var postperpage = jQuery(rel).data('postsperpage');
      var category = jQuery(rel).data('category');
      var container = jQuery(rel).data('cont');
      var order = jQuery(this).val();
      var orderby = jQuery(this).find(":selected").data('orderby');
      requestPagi(rel, postperpage, category, 1, order, orderby, container);
    });

    $('#consegnacontrol').on('change', function () {
      if (this.checked) {
        $('.consegna').slideDown();
      } else {
        $('.consegna').slideUp();
        $('.consegna input, .consegna select').val('');
        $('.consegna select').change();

      }
    });

    /**** CONTROLLARE ****/
    $('.aprimodal').on('click', function (e) {
      e.preventDefault();
      $('#myPrivacy').modal('show');
    });

  });

}(jQuery));