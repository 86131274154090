function initPaciniEditore() {

    jQuery(document).ready(function() {

        
        document.addEventListener( 'wpcf7mailsent', function(event) {
            
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'form_type': 'contact',
                'submission_result': 'submit_ok',
                'event': 'generate_lead',
                'GFTrackCategory': 'modulo_di_contatto',
                'GFTrackAction': 'clic',
                'GFTrackLabel': 'Modulo di contatto',
                'GFTrackValue': 0,
                'GFTrackSource': '',
                'GFTrackMedium': '',
                'GFTrackCampaign': '',
                'GFTrackTerm': '',
                'GFTrackContent': '',
            });
        });
        document.addEventListener( 'wpcf7mailfailed', function(event) {
            
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'form_type': 'contact',
                'submission_result': 'submit_fail',
                'event': 'generate_lead',
                'GFTrackCategory': 'modulo_di_contatto',
                'GFTrackAction': 'clic',
                'GFTrackLabel': 'Modulo di contatto',
                'GFTrackValue': 0,
                'GFTrackSource': '',
                'GFTrackMedium': '',
                'GFTrackCampaign': '',
                'GFTrackTerm': '',
                'GFTrackContent': '',
            });
        });

        /*window.cookieconsent.initialise({
            'palette': {
                'popup': {
                    'background': '#000000',
                },
                'button': {
                    'background': '#ffffff',
                },
            },
            'position': 'bottom-right',
            'content': {
                'dismiss': 'Accetta e continua',
                'message': 'Questo sito utilizza cookies tecnici funzionali all\'esperienza di navigazione e cookies, anche di terze parti, per offrirti servizi in linea con le tue preferenze.\r\nSe vuoi saperne di pi\u00F9 o negare il consenso a tutti o ad alcuni dei suddetti cookies clicca il pulsante \"informativa estesa\". Se accetti cliccando su \"Accetta e continua\" acconsenti all\'uso dei cookie.',
                'link': 'Informativa Estesa',
                'href': '/cookie/',
            },
        });*/

        jQuery("#search-btn").click(function() {
            jQuery(".ricercafunzionanle").fadeIn();
            jQuery("body").addClass("noverflw");
        });
        jQuery(".closecerca").click(function() {
            jQuery(".ricercafunzionanle").fadeOut();
            jQuery("body").removeClass("noverflw");
        });

        jQuery('.owl-carousel-autoconfig, #pe19-slide-home').each(function(i, e) {
            var owl_elm = jQuery(e);
            var owl_max = 4;
            var owl_autoplay = false;

            if (owl_elm.data('maxslide')) {
                owl_max = owl_elm.data('maxslide');
            }
            if (owl_elm.data('autoplay')) {
                owl_autoplay = true;
            }

            var owl_obj_respo = {};
            if (owl_max === 1) {
                owl_obj_respo = { 0: { items: 1 } };
            } else if (owl_max === 2) {
                owl_obj_respo = { 0: { items: 1 }, 480: { items: 2 } };
            } else if (owl_max === 3) {
                owl_obj_respo = { 0: { items: 1 }, 480: { items: 3 } };
            } else if (owl_max === 4) {
                owl_obj_respo = { 0: { items: 1 }, 480: { items: 2 }, 768: { items: 4 } };
            } else if (owl_max === 5) {
                owl_obj_respo = { 0: { items: 1 }, 480: { items: 2 }, 768: { items: 3 }, 1000: { items: 5 } };
            } else if (owl_max === 6) {
                owl_obj_respo = { 0: { items: 1 }, 480: { items: 2 }, 768: { items: 3 }, 900: { items: 5 }, 1100: { items: 6 } };
            }

            owl_elm.on('initialized.owl.carousel', function(event) {
                owl_elm.addClass('owl-carousel-show');
            });
            owl_elm.owlCarousel({
                items: owl_max,
                nav: true,
                dots: false,
                lazyLoad: true,
                autoHeight: true,
                responsive: owl_obj_respo,
                autoplay: owl_autoplay,
                autoplayTimeout: (5000 + (i * 1500)),
            });

        });


        if (jQuery('#comingsoonslider').length) {
            jQuery('#comingsoonslider .carousel-inner .item:first-of-type').addClass('active');
            jQuery('#comingsoonslider .carousel-indicators li:first-of-type').addClass('active');
        }

        if (jQuery('#calendar').length && window.dataCalendar !== undefined) {
            new Calendar('#calendar', window.dataCalendar, 'it');
        }

        jQuery(window).resize(function() {
            var maxh = 0;
            jQuery('#pe19-slide-home .owl-item').each(function(i, e) {
                var h = jQuery(e).height();
                if (h > maxh) {
                    maxh = h;
                }
            });

            jQuery('#pe19-slide-home .owl-item').css('min-height', maxh + 'px');

        }).trigger('resize');

        /*if(jQuery('#billing_salutation_field select').length) {
          jQuery('#billing_salutation_field select').select2();
        }
        if(jQuery('#shipping_salutation_field select').length) {
          jQuery('#shipping_salutation_field select').select2();
        }*/

        jQuery("#billing_invoice_type option[value='professionist_invoice']").remove();

        jQuery('#billing_invoice_type').on('select2:select', function(e) {
            if (jQuery(this).val() === 'private_invoice') {
                jQuery('.pe19-richiedi-ricevuta-field').show(200);
                jQuery('#billing_invoice_type_field').addClass('full_width_checkout_custom');
                jQuery('#billing_invoice_type_field').removeClass('half_width_checkout_custom');
            } else {
                jQuery('.pe19-richiedi-ricevuta-field').hide(200);
                jQuery('#billing_invoice_type_field').removeClass('full_width_checkout_custom');
                jQuery('#billing_invoice_type_field').addClass('half_width_checkout_custom');
            }
            console.log('eccolo', jQuery(this).val());
        });

        if (jQuery('#billing_invoice_type').val() === 'private_invoice') {
            jQuery('.pe19-richiedi-ricevuta-field').show(200);

        } else {
            jQuery('.pe19-richiedi-ricevuta-field').hide(200);
        }

        jQuery('.woovr-variation.woovr-variation-radio').click(function() {
            jQuery('.woocommerce-variation-add-to-cart.variations_button').addClass('clicked');
        });

        /*jQuery('.infosidelibro > p.price > ins').append('<span class="pe19-promo-info-single">PROMOZIONE FINO AL 31 DICEMBRE 2020</span>');
        jQuery('.woovr-variation-price ins').append('<span class="pe19-promo-info-var">PROMOZIONE FINO AL 31 DICEMBRE 2020</span>');*/

        jQuery('.wrapper-video .overlay-yt').click(function () {
          jQuery(this).fadeOut(500);
          jQuery(this).siblings('iframe').attr('src', jQuery(this).siblings('iframe').attr('src') + '?autoplay=1');
        });

        jQuery('label[for=payment_method_ppcp-gateway]').after( "<a href='https://www.paypal.com/it/webapps/mpp/home' class='link-paypal-custom' target='_blank'>Che cos'è Paypal?</a>" );

        jQuery('.woovr-variation-name').each(function(i, el){
            if (jQuery(this).text() === "Volume") {
                jQuery(this).click();
                jQuery(this).click();
            } else if ( i === 0) {
                jQuery(this).click();
                jQuery(this).click();

            }
        });     
    });
}

jQuery('label[for=billing_pa_code]').text('Codice Identificativo Destinatario');
jQuery('label[for=billing_piva]').text('Partita IVA');


function checkInitEditore() {
    jQuery(document).ready(function() {
        jQuery('body').imagesLoaded({ background: true }, function() {
            initPaciniEditore();
        });
    });
}

